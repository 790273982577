<template>
    <div class="agree-ment">
        <div class="top" v-if="showTop">
            <div class="back" @click="$router.back()">
                <i class="iconfont icon-back_icon"></i>
            </div>
            <div class="langugage">
                <!-- <span
        :class="['lang', { active: lang === 'zh_CN' }]"
        @click="selectLang('zh_CN')"
        >简</span
      >
      <span>/</span> -->
                <span :class="['lang', { active: lang === 'zh_HK' }]" @click="selectLang('zh_HK')">繁</span>
                <span>/</span>
                <span :class="['lang', { active: lang === 'en_US' }]" @click="selectLang('en_US')">EN</span>
            </div>
        </div>

        <div>
            <h3 class="title">{{ info.title }}</h3>
            <div class="content" v-html="info.content"></div>
        </div>
    </div>
</template>

<script>
import { getItem, setItem } from '@/utils/local.js';
export default {
    name: 'AgreeMent',
    data() {
        return {
            lang: this.getItem('lang') || 'zh_HK', // 语言
            showTop: true, // 是否显示顶部操作区
            info: {},
        };
    },
    created() {
        let { query } = this.$route;
        if (['en_US', 'zh_HK', 'zh_CN'].includes(query.lang) || ['en_US', 'zh_HK', 'zh_CN'].includes(query.type)) {
            this.lang = query.lang || query.type;
            this.$i18n.locale = query.lang || query.type;
            this.$setItem('lang',this.lang)
            this.showTop = false;
        }
        this.getPageDetail();
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        getItem,
        selectLang(lang) {
            this.$setItem('lang', lang);
            window.location.href = window.location.href.split('?').shift();
        },
        getPageDetail() {
            let name = this.$route.query.name || this.$route.meta.name;
            this.$api.getPageDetail({ name, source: 4 }).then((res) => {
                if (res.code == 200) {
                    this.info = res.data;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    width: auto;
}
.agree-ment {
    padding: 30px;
    line-height: 2;
    div.top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .langugage {
        padding-right: 100px;
        text-align: right;
        span {
            padding: 0 5px;
            &.lang {
                cursor: pointer;
            }
            &.active {
                color: red;
            }
        }
    }
    .icon-back_icon {
        cursor: pointer;
    }
    h3.title {
        text-align: center;
        padding: 10px 0;
    }
    .content {
        h4 {
            padding: 5px 0;
        }
    }
}
</style>
